import React, { useState } from 'react';

const NavScrollContext = React.createContext();

export function NavScrollProvider({ children }) {
  const [navScroll, setNavScroll] = useState([]);

  return (
    <NavScrollContext.Provider value={[navScroll, setNavScroll]}>
      {children}
    </NavScrollContext.Provider>
  );
}

export default NavScrollContext;